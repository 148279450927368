/***
    Fonts Import
****/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://use.typekit.net/bex3dvl.css");
/******* Colors css file ********/
@import url("colors.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--sx-font--hedley) !important;
}
h1.h1-v1,
h1.h1-v2,
h1.h1-v3 {
  font-size: 72px;
  line-height: 82px;
  font-style: normal;
}
h1.h1-v1 {
  font-weight: 400;
}
h1.h1-v2 {
  font-weight: 500;
}
h1.h1-v3 {
  font-weight: 700;
}
h2 {
  font-size: 54px;
  line-height: 60px;
  font-weight: 400;
  font-style: normal;
}
h3 {
  font-size: 42px;
  line-height: 48px;
  font-weight: 400;
  font-style: normal;
}
h4 {
  font-size: 36px;
  line-height: 41px;
  font-weight: 400;
  font-style: normal;
}
h5 {
  font-size: 32px;
  line-height: 35px;
  font-weight: 400;
  font-style: normal;
}
body {
  margin: 0;
  font-family: var(--sx-font--poppins) !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: var(--color-black);
}
.MuiTableCell-root,
.MuiTypography-root,
.MuiSelect-root,
.MuiInputLabel-root,
.MuiButton-root {
  font-family: var(--sx-font--poppins) !important;
}
/* Provide sufficient contrast against white background */
a {
  color: var(--color-blue);
}

code {
  color: #e01a76;
}
.sx-blue {
  color: var(--color-blue) !important;
}
.sx-black {
  color: var(--color-black);
}
.sx-white {
  color: var(--color-white) !important;
}
.sx-bold {
  font-weight: 700 !important;
}
.sx-semiBold {
  font-weight: 600 !important;
}
.sx-uppercase {
  text-transform: uppercase;
}
.sx-grey {
  color: var(--color-grey);
}
.sx-green {
  color: var(--color-green);
}
.sx-yellow {
  color: var(--color-yellow);
}
.sx-red {
  color: var(--color-red);
}
.sx-colorBg-grey-08 {
  background-color: var(--color-grey-08);
}
.sx-textButton {
  font-weight: 600 !important;
  color: var(--sx-color--blue) !important;
}
.sx-textButton:hover {
  color: var(--sx-color--blue-02) !important;
  background-color: transparent !important;
}
.MuiButton-contained.sx-button {
  background-color: var(--sx-color--blue) !important;
  color: var(--color-white);
  height: 40px;
  min-width: 120px;
  border-radius: 50px !important;
  font-weight: 600 !important;
}
.MuiButton-contained.sx-button:hover,
.MuiButton-contained.sx-buttonWithOutBg:hover,
.MuiButton-contained.sx-buttonWithWhiteBg:hover {
  background-color: var(--sx-color--blue-02) !important;
  color: var(--color-white) !important;
}
.MuiButton-contained:active.sx-button {
  background-color: var(--sx-color--blue-03);
}
.MuiButton-contained.sx-button.sx-placeOrderBtn {
  background-color: var(--color-green-02) !important;
  color: var(--color-white) !important;
}
.MuiButton-contained.sx-button.sx-placeOrderBtn:hover {
  background-color: var(--color-green-03) !important;
}
.MuiButton-contained.sx-buttonWithOutBg {
  background-color: transparent !important;
  color: var(--sx-color--blue-02);
  height: 40px;
  min-width: 120px;
  border-radius: 50px !important;
  font-weight: 600 !important;
}
.MuiButton-contained.sx-buttonWithWhiteBg {
  background-color: var(--color-white) !important;
  color: var(--sx-color--blue-02);
  height: 40px;
  min-width: 120px;
  border-radius: 50px !important;
  font-weight: 600 !important;
}
.sx-headerLogo {
  flex: 0 0 200px;
  max-width: 200px;
  z-index: 99;
}
.sx-login {
  padding: 90px 0px;
}
.sx-loginBg {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: rgb(145 158 171 / 24%) -24px 24px 72px -8px;
}
h1.sx-loginTitle {
  margin: 0px 0px 16px;
  font-family: var(--sx-font--hedley);
  font-weight: 700;
  line-height: 1.5;
  font-size: 32px;
}
h5.sx-loginSubTitle {
  margin: 0px;
  line-height: 26px;
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-grey);
  border-top: 1px solid #eee;
}
.sx-inputGroup .sx-input {
  border-radius: 10px !important;
  background-color: var(--color-grey-08) !important;
  border: 1px solid #cbd5e1 !important;
}
.sx-inputGroup .MuiFilledInput-root.sx-input {
  background-color: var(--color-grey-08) !important;
  overflow: hidden;
}
.sx-inputGroup .sx-input.Mui-focused,
.sx-inputGroup .sx-input:hover {
  border-color: var(--sx-color--blue) !important;
  background-color: var(--color-grey-09);
}
.sx-inputGroup .sx-input-label.Mui-focused {
  color: var(--sx-color--blue) !important;
}
.sx-signUpBg {
  background-color: #e3f2fd;
  display: flex;
  min-height: 100vh;
  align-items: center;
}
.sx-loginLogo {
  height: 70px;
}
.link {
  cursor: pointer;
}
.font-12 {
  font-size: 12px !important;
}
.font-10 {
  font-size: 10px !important;
}
.error-text {
  color: red !important;
}
.sx-header-userAvatar {
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.sx-border-left {
  border-left: 1px solid #D1D1D1;
}
.sx-border-right {
  border-right: 1px solid #D1D1D1;
}
.sx-header-myAccountName {
  max-width: 210px;
  font-size: 20px;
  line-height: 30px;
  
}
.sx-navigation {
  background-color: var(--color-white);
}
.sx-lh-initial {
  line-height: initial;
}
header nav, header {
  background-color: var(--color-white);
}
header{
  box-shadow: 0px 10px 10px 0 rgba(0,0,0,0.09);
  position: relative;
}
ul.nav{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 500;
}
ul.nav li{
  flex-grow: 1;
  --mfn-header-menu-icon-gap: 10px;
  --mfn-header-menu-icon-size: 25px;
  display: inline-flex;
  margin: 0 calc(var(--mfn-header-menu-gap) / 2);
  position: static;
}
.sx-navigation .nav-item .nav-link {
  cursor: pointer;
  color: var(--color-black);
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    padding:20px 15px;
    border-right: 1px solid #ececec;
}
.sx-navigation .nav-item .nav-link img, .sx-header-userAvatar img{
  height: 22px;
}
.sx-navigation .nav-item .nav-link.active,
.sx-navigation .nav-item .nav-link:hover {
  color: var(--sx-color--blue);
}
.sx-navigation .nav-item .nav-link.active img,
.sx-navigation .nav-item .nav-link:hover img,
.sx-header-userAvatar:hover img{
  filter: sepia(100%) hue-rotate(140deg) saturate(1350%);
}
.nav-item .nav-link .h-26{
  height: 26px !important;
}
.nav-item .nav-link .sx-navSubText{
  background-color: #BEE2EF !important;
  color:var(--sx-color--blue) !important;
  font-size: 14px !important;
  line-height: 28px !important;
  padding: 2px 8px;
    border-radius: 20px;
    text-transform: capitalize;
}
.sx-bannerAuto {
  height: auto;
}
.sx-banner {
  position: relative;
  overflow: hidden;
}
.sx-bannerBlock-img {
  position: relative;
  object-fit: cover;
}
.sx-myAccount {
  position: absolute;
  background-color: #ffffff;
  z-index: 3;
  right: 0;
  -webkit-box-shadow: 0 24px 54px rgb(0 0 0 / 15%),
    0 4.5px 13.5px rgb(0 0 0 / 8%);
  box-shadow: 0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%);
}
.sx-hiImage {
  height: 30px;
}
.sx-loginAccountName {
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  border-radius: 100px;
  font-size: 2rem;
  font-weight: 600;
}
.sx-switchAccount {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0.75rem;
  text-align: center;
  color: #777;
}
.sx-switchAccount:hover,
.sx-logoutBtn:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
}
.sx-chnageIcon {
  height: 27px;
}
.sx-underLine {
  text-decoration: underline !important;
}
@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
.sx-border-bottom {
  border-bottom: 1px solid var(--color-grey-05);
}
.sx-border-top {
  border-top: 1px solid var(--color-grey-05);
}
.sx-table-shadow {
  /* box-shadow: 0 0 0.25rem rgb(0 0 0 / 13%) !important; */
  box-shadow: 0 1rem 3rem rgb(0 54 100 / 18%) !important;
}
.sx-textWithIcon {
  position: relative;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  user-select: none;
  appearance: none;
  max-width: 100%;
  font-family: "Public Sans", sans-serif;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  color: rgb(33, 43, 54);
  background-color: var(--color-grey-04);
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
}
.sx-buttonIcon {
  color: rgb(99, 115, 129) !important;
  background-color: rgba(145, 158, 171, 0.48) !important;
  margin: 0px 5px !important;
}
.sx-label {
  font-size: 13px;
  color: var(--color-black);
  font-weight: 600;
}
.sx-tableSearch {
  background-color: var(--color-grey-04) !important;
  border-radius: 15px !important;
  max-width: 600px;
}
.sx-tableSearch .ForwardRef-searchContainer-21 {
  order: 2;
}
.ForwardRef-searchIconButton-18 .MuiSvgIcon-root {
  color: var(--sx-color--blue) !important;
}
.sx-bgTransparent {
  background-color: transparent !important;
}
.sx-iconButtonImg {
  opacity: 0.9;
}
.sx-iconButtonImg:hover {
  opacity: 1;
}
.helper-text {
  color: red !important;
  margin-top: 2px;
}
.sx-tableHeader {
    background-color: var(--color-blue-04) !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 12;
}
.sx-tableHeader th,
.sx-tableHeader .MuiTableSortLabel-root.MuiTableSortLabel-active,
.sx-tableHeader
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon,
.sx-tableHeader .MuiTableSortLabel-root:hover {
  color: var(--color-blue) !important;
  border-bottom: none;
}
.sx-tableHeader th p,
.sx-tableHeader th,
.sx-tableBody td,
.sx-tableBody td p {
  font-size: 13px;
  white-space: nowrap;
}
.sx-tableHeader th p,
.sx-tableHeader th {
  font-weight: 600 !important;
}
.sx-tableBody td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.sx-tableBody .sx-button {
  height: 30px;
  min-width: 85px;
  line-height: 30px;
  border-radius: 30px !important;
  font-size: 8px;
}
.sx-childTable .sx-tableHeader th p,
.sx-childTable .sx-tableHeader th,
.sx-childTable .sx-tableBody td,
.sx-childTable .sx-tableBody td p {
  font-size: 12px;
}
.table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}
.sx-statusToggle .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #3bb178;
}
.sx-statusToggle .MuiSwitch-colorPrimary.Mui-checked {
  color: #3bb178;
}
.sx-tableSearchContainer .sx-button {
  height: 38px;
}
.sx-addRegistrationDialog .sx-inputGroup {
  margin: 15px 5px 8px !important;
  width: 47%;
}
.w-96 {
  width: 96% !important;
}
.sx-model .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.7);
}
.sx-model .sx-button--white {
  border: 1px solid var(--color-black);
  color: var(--color-black);
}
.sx-model .sx-button {
  height: 40px;
  min-width: 150px;
  line-height: 40px;
  border-radius: 50px !important;
}
.sx-model-title {
  background-color: var(--color-grey-05) !important;
}
.sx-model-title h2 {
  font-weight: 600 !important;
}
.sx-accountsBoard .sx-accountsBoard-card {
  padding-right: 5px;
}
.sx-accountsBoard .sx-accountsBoard-card:last-child {
  padding-right: 15px;
}
.sx-accountsBoard .card-title {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}
.sx-accountsBoard .card-text {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.sx-accountBoard-btn {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.sx-accountsBoard-card-greenBg {
  color: var(--color-green);
  background-color: #e8fff3;
  border-color: rgb(66 189 134 / 40%);
}
/* .sx-accountsBoard-card-greenBg:hover, */
.sx-accountsBoard-card-greenBg.active {
  background-color: #009552;
}
.sx-accountsBoard-card-yellowBg {
  color: var(--color-yellow);
  background-color: #fff8dd;
  border-color: rgb(225 158 40 / 40%);
}
/* .sx-accountsBoard-card-yellowBg:hover, */
.sx-accountsBoard-card-yellowBg.active {
  background-color: #e19e28;
}
.sx-accountsBoard-card-redBg {
  color: var(--color-red);
  background-color: #fff5f8;
  border-color: rgb(208 68 79 / 40%);
}
/* .sx-accountsBoard-card-redBg:hover, */
.sx-accountsBoard-card-redBg.active {
  background-color: #bd1515;
}
.sx-accountsBoard-card-blueBg {
  color: var(--color-blue-05);
  background-color: #f1faff;
  border-color: rgb(0 123 255 / 40%);
}
/* .sx-accountsBoard-card-blueBg:hover, */
.sx-accountsBoard-card-blueBg.active {
  background-color: #007bff;
}
.sx-accountsBoard-card-plain {
  border-color: rgb(40 102 128 / 40%);
  background-color: #eaf0f3;
}
/* .sx-accountsBoard-card-plain:hover, */
.sx-accountsBoard-card-plain.active {
  background-color: #286680;
}
/* .sx-accountsBoard-card-greenBg:hover,
.sx-accountsBoard-card-yellowBg:hover,
.sx-accountsBoard-card-redBg:hover,
.sx-accountsBoard-card-blueBg:hover,
.sx-accountsBoard-card-plain:hover, */
.sx-accountsBoard-card-greenBg.active,
.sx-accountsBoard-card-yellowBg.active,
.sx-accountsBoard-card-redBg.active,
.sx-accountsBoard-card-blueBg.active,
.sx-accountsBoard-card-plain.active {
  color: #fff !important;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}
/* .sx-accountsBoard-card-greenBg:hover .sx-green,
.sx-accountsBoard-card-yellowBg:hover .sx-yellow,
.sx-accountsBoard-card-redBg:hover .sx-red,
.sx-accountsBoard-card-blueBg:hover .sx-blue-02,*/
.sx-accountsBoard-card-greenBg.active .sx-green,
.sx-accountsBoard-card-yellowBg.active .sx-yellow,
.sx-accountsBoard-card-redBg.active .sx-red,
.sx-accountsBoard-card-blueBg.active .sx-blue-02 {
  color: #fff !important;
}
.absolute {
  position: absolute;
}
.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}
.opacity-30 {
  opacity: 0.3;
}
.sx-translate-y {
  transform: translate(16%, 6%) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.w-24 {
  width: 6rem;
}
.sx-accountStatus {
  display: flex;
  border-radius: 3px;
  width: 6px;
  height: 27px;
}
.sx-bgGreen {
  background-color: var(--color-green) !important;
}
.sx-bgRed {
  background-color: var(--color-red) !important;
}
.sx-bgBlue {
  background-color: var(--color-blue-05) !important;
}
.sx-bgYellow {
  background-color: var(--color-yellow) !important;
}
.sx-bgDark {
  background-color: var(--color-black) !important;
}
.sx-bgDark2 {
  background-color: #286680 !important;
}
.sx-tipImg {
  cursor: pointer;
  z-index: 1;
  width: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAG0SURBVEiJrdXNbk1RFAfwn6YRkVYMfEWNGqkIBu47iNRAU9yBBCOPoDWjQxLRSXkFow4waTSiQc18JR7CoCgGgnIM9rpxe5xz7tmJf7Jz7177vz73XuuQh7u4k6MwnOngcCY/28FGroMyuphtOO/EqsNs2GgkFLidHVrSKTQHCObxG7tiP4YbeIsv+IrXuI69wdkdOvNto+kZP42P+IXHEeUtPJLu4wPOlnRaYyqieoHxkB3AFZzCITwLR2dyjY9F5KvY2ifvhsFCKtMEnkuZ7MlxcFMqy3jF2Q5cwCe8knpjQ7qT1ngj1bwJF6VMJoP7soo0hAUsYwnHQ74f7wY4eCBleVR6YQdD3glby1gYqlHeFspN+IzzuBf7ovRb/r8Jq1gZ4KAftSWqw5x0cUdacCeCey3HwU6sST0w0sDbHpw1mc+U1Gg/8BSjFecjeIKfmM413mv7aXzHYgVnMQLolnQGojzsehP2WB+nY/PkbD3sqsb1vpDdx9VYD0PWH3Wrcd3FTEm2JRSrVhkzODcoiyoU0tPtYa7GwT9o+01+j5P4FvsTUif/N1zCur+lWcflNop/AEx0b95b4lhEAAAAAElFTkSuQmCC");
}
.sx-tipImg:hover {
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmDB0FARKHI7KeAAAD0klEQVRYw82XT2hUVxTGfy8VkVSjUI02uhAbImn+QEK7CwiCpElpQlItBE2QViwtVmjrH4oLAy1UEaK0MatW3BQJJIsoFEsQgxmFJjP3TlLTdtFiVzZQjYlCJZq5Xxdv3swkY5rMdEZ6Npd3333n+8537j33PMiTSaYTenokUwHnz+cLZ0W+HCP+hPJyoC4+M5EPmIK8BQDNMDeXMj5fk7MG9uyRzMtw7FjG3yschtraYMz8ex834JF5AIEDGQOSnG2Frq7nIFwrdHUlcLMUMCUQ2wtnz0rmG3BOLjwK69cn30d7YPNmyRbBqVOSBcbGJFMPDx9K5gQ8eiRnvgRrJXsBTp6U++kz2LgxhXgdbNiQwInjLsXPW74yPnGv4LXX4d49yYSgqQkYgosXgbuwdi3yxmFoCCiGsTFQEzgHjEN1NZ43Ajt3gl6CmRngHTh40PNq90J//0Kc3KdY9j1obk4qZX6HW7ek0UbYti0Z8PgbsGWLnP0YjhyR7DlobJRGT8D27ZI9BMPDkq2DuTnJvAitrTknnL5VzBcwNSWZb+HmTbmJ3bByZXrGgiKQIBg/S/Gt5EZKoKxMMu0QCkmmGe7flxv7FIqL86H8B3D6tGQ+hFhsoeKLfud+fAWKiuQik7Bvn2R+gAcP5EwDGOMrX16eDNQ/I3kIwIQgGvUP27VrWftx5hNob0+pMnehoSHwK9k3IRJZrr8Cf292d8uZ72FwULJvwdWrUnQWamrmLy8pwdPbcPt29lK4Nrh8GbzDEIsh72+orCRx6PkFSkuTwgX3SZxXkuc56O7O5Cb+HFatQt4wxGLZ0very8wM4itoa4MXDsGlSyk5CoOUfF5RNf85YX2LzD/bEodWpjdeJnNqWW+hDCDehcFB8L6Gujop2gEVFf+dePRnKCvzb6QdO4AZGBjItUBIVrBunWSr4xeZX//dxG5YvTpzf+EwFBYm75G433yV0ZRAgovsV3jyRLKVcOOGXOgvWLNmacX9gOXM+3D9umQL4elTKfIRtLTkj3haD+QDSuY7mJ2Vs69Cf//Sfvx1CQEWdJsLcXKh+BLN3MKuNTwKVVXpfoJy+OwuM9tm7l+IL6+dlkZ6YdOmJLHIfhgY8FuO48eTY2QarlyZH2i60pm204t2oympLYKtW72CmlI4cyY9AAk8D6yNd53LMs+rrfW/WxT/Nzh6FNgPd+54BTUh6OvLOiPLyFiQAUFnZ/p7fz5YlyvcHP7Ue4dhchJ5f0B9vb91Hj9OWXABdu0CWuL/Af8vkzOz0NEhmSmYnk7Zw8Hozzu7Fw4cyBXuPwID76v9PO9MAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTEyLTI5VDA1OjAxOjE4KzAwOjAwN/CaCAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0xMi0yOVQwNTowMToxOCswMDowMEatIrQAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjItMTItMjlUMDU6MDE6MTgrMDA6MDARuANrAAAAAElFTkSuQmCC");
}
.sx-topLeftRightBorderRadius-0 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.sx-accountsBoard-card-greenBg.active .sx-button,
.sx-accountsBoard-card-yellowBg.active .sx-button,
.sx-accountsBoard-card-redBg.active .sx-button,
.sx-accountsBoard-card-blueBg.active .sx-button,
.sx-accountsBoard-card-plain.active .sx-button {
  background-color: transparent !important  ;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--sx-color--blue) !important;
}
.MuiCheckbox-root {
  color: var(--color-grey-06) !important;
}
.Mui-disabled.MuiCheckbox-root,
.sx-inputGroup.Mui-disabled {
  color: var(--color-grey-07) !important;
}
.sx-inputGroup:hover .MuiCheckbox-root,
.MuiCheckbox-root:hover {
  color: var(--sx-color--blue) !important;
}
.sx-placeOrderTitle {
  font-size: 1.5rem;
}
.sx-placeOrder {
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: var(--sx-color--blue) !important;
}
.sx-placeOrderBg {
  background-color: #d5effa !important;
  border-bottom-right-radius: 40px !important;
}
.MuiFilledInput-input {
  padding: 22px 12px 7px !important;
}
.sx-customSelect .MuiSelect-select {
  padding: 15px 12px 15px !important;
  min-width: 70px;
}
.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 10px) scale(0.7) !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
/****************Custom Dropdown Styles******************/
.MuiMenuItem-root {
  justify-content: space-between !important;
}
.MuiList-root.MuiMenu-list .MuiListItem-button.Mui-selected::after {
  content: url("../src/images/tickmark.png");
  display: inline-block;
  margin-left: 5px;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #8dc5da !important;
  color: #fff !important;
}
.MuiListItem-button:hover {
  text-decoration: none;
  background-color: #eef4f6 !important;
}
.MuiList-root.MuiMenu-list .MuiListItem-button.disabled::after {
  content: "" !important;
}
.MuiListItem-root.disabled,
.MuiListItem-root.disabled:hover {
  background-color: initial !important;
  color: initial !important;
}
.upload-file.MuiButton-outlined {
  height: 48px;
  background-color: var(--color-grey-08);
  border: 1px solid var(--color-grey-08);
}
.sx-inputGroup.sx-validationFailed .sx-input,
.sx-validationFailed.sx-input {
  background-color: var(--color-red-02) !important;
  border-color: var(--color-red) !important;
}
.sx-inputGroup.sx-validationFailed .sx-input-label {
  color: var(--color-red) !important;
}
.pagination-content .MuiTablePagination-actions .MuiIconButton-colorInherit {
  border: 2px solid var(--color-blue) !important;
  border-radius: 15px;
  height: 34px;
  margin: 0 7px;
  padding: 9px 18px !important;
}
.pagination-content
  .MuiTablePagination-actions
  .MuiIconButton-colorInherit:first-child {
  content: url("../src/images/leftArrow.png");
}
.pagination-content
  .MuiTablePagination-actions
  .MuiIconButton-colorInherit:last-child {
  content: url("../src/images/rightArrow.png");
}
.pagination-content
  .MuiTablePagination-actions
  .MuiIconButton-colorInherit:first-child:hover {
  content: url("../src/images/leftArrow-white.png");
  background-color: var(--color-blue);
}
.pagination-content
  .MuiTablePagination-actions
  .MuiIconButton-colorInherit:last-child:hover {
  content: url("../src/images/rightArrow-white.png");
  background-color: var(--color-blue);
}
.pagination-content
  .MuiTablePagination-actions
  .MuiIconButton-colorInherit.Mui-disabled {
  opacity: 0.26 !important;
}
.snackbar .alert {
  font-weight: 600;
  font-size: 15px;
  border-radius: 50px;
}
.snackbar .alert-success {
  color: var(--color-green-04);
  background-color: var(--color-green-05);
  border-color: var(--color-green-05);
}
.home-banner {
  max-height: 90vh;
}
.no-borderRadius {
  border-radius: 0px !important;
}
.btn-green {
  background-color: var(--color-green-02) !important;
  border-color: var(--color-green-02) !important;
  color: var(--color-white) !important;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
}
.btn-outline-blue{
  background-color: transparent !important;
  border: 3px solid var(--sx-color--blue) !important;
  color: var(--sx-color--blue) !important;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 600 !important;
  font-size: 1rem !important;
}
.btn-green:hover {
  background-color: var(--color-green-03) !important;
  border-color: var(--color-green-03) !important;
  text-decoration: none;
}
.btn-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}
.sx-hideOnDesktop {
  display: none !important;
}
.sx-navigationMobileOnly {
  display: none;
}
nav.navbar{
  padding:20px 50px;
}
@media screen and (max-width: 960px) {
  nav.navbar{
    padding:20px;
  }
  .sx-hideOnDesktop {
    display: block !important;
  }
  .sx-hideOnDesktop.d-flex{
    display: flex !important;
  }
  .sx-headerLogo {
    flex: 0 0 130px;
    max-width: 130px;
  }
  .sx-hideOnMobile,
  .sx-navigationDesktopOnly {
    display: none !important;
  }
  .sx-navigationMobileOnly {
    position: fixed;
    height: -webkit-fill-available;
    width: 100%;
    z-index: 11;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    top: 90px;
    padding-top: 10px;
  }
  .sx-headerLogo {
    padding: 0px !important;
  }
  .sx-headerLogo img {
    max-height: 36px !important;
  }
  .sx-navigationMobileOnly.sx-navigation {
    background-color: var(--color-white) !important;
    overflow: auto;
  }
  .sx-navigationMobileOnly.sx-navigation .nav-link {
    border-bottom: 1px solid #ececec;
    margin: 0px 15px;
    padding: 35px 0.7rem 0.7rem;
    width: 100%;
    border-right: none !important;
  }
  .sx-navigation .nav-item .nav-link.active,
  .sx-navigation .nav-item .nav-link:hover {
    color: var(--sx-color--blue) !important;
  }
  .mb-xs-3 {
    margin-bottom: 1rem !important;
  }
  .sx-accountsBoard .card-title {
    height: 2.2rem !important;
  }
  .mx-xs-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .sx-accountsBoard .sx-accountsBoard-card:last-child,
  .sx-accountsBoard .sx-accountsBoard-card {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .col.sx-accountsBoard-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sx-tableSearch {
    width: 65%;
  }
  .sx-tableSearchContainer .sx-button {
    min-width: 38px !important;
  }
  .sx-addRegistrationDialog .sx-inputGroup {
    width: 100%;
  }
  .sx-myAccountMobileOnly {
    background-color: var(--color-grey-02);
    padding: 15px;
    margin: 0px 15px;
    border: 1px solid var(--color-grey-04);
    border-radius: 10px;
  }
  .MuiMenuItem-root {
    white-space: normal !important;
  }
}
@media screen and (max-width: 960px) {
  h2 {
    font-size: 36px;
  }
}
.position-fixed {
  position: fixed;
  width: 100%;
  z-index: 111;
}
.sx-textTransformCapitalize {
  text-transform: capitalize !important;
}
.sx-inputGroup .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.sx-inputGroup .MuiFilledInput-root.Mui-focused,
.sx-inputGroup .MuiFilledInput-root,
.sx-inputGroup .MuiFilledInput-root:hover {
  background-color: transparent;
}
.sx-inputGroup .MuiFilledInput-underline:hover:before,
.sx-inputGroup .MuiFilledInput-underline:before,
.sx-inputGroup .MuiFilledInput-underline:after {
  border-bottom: none;
}
.sx-inputGroup .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-top: 3px;
}
.d-flex.gap-4{
  column-gap: 20px;;
}

.sx-inputGroup .sx-multitext {
  border-radius: 10px !important;
  background-color: var(--color-grey-08) !important;
  /* border: 1px solid #274f81 !important; */
}
.sx-inputGroup1 {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 4px;
}

.sx-input-label1 {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: bold;
}

.sx-checkbox-label {
  margin-bottom: 4px;
}
.small-button {
  padding: 4px 8px; /* Adjust padding for smaller size */
  font-size: 12px;  /* Smaller font size */
  line-height: 1.5; /* Adjust line height for better alignment */
}
.sx-childTable.enhancedTableHead thead
{
  visibility: hidden !important;
}
.sx-invisible-icon{
  width: 32px;
  height: 24px;
  display: inline-block;
}
.sticky-column {
    position: sticky;
    left: 0; /* Adjust the value for each column */
    background-color: white; /* Match the table's background */
    z-index: 2; /* Ensure it stays on top of other elements */
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow */
}

    .sticky-column:nth-child(2) {
        left: 100px; /* Adjust left for the second sticky column */
    }

    .sticky-column:nth-child(3) {
        left: 200px; /* Adjust left for the third sticky column */
    }

/*scrollable table*/
.sticky-header th {
position: -webkit-sticky; /* Safari support */
position: sticky;
top: 0;
z-index: 1; /* Ensure it stays above the rows */
/* border-bottom: 1px solid #ddd; Optional: For a clearer header */
}


.scrollable-table {
    max-height: 500px; /* Define the scrollable area */
    overflow-y: auto; /* Enable vertical scrolling */
    /* display: block; */
}

.table {
    width: 100%;
    /* table-layout: fixed; Ensure columns have a consistent width */
    border-collapse: collapse;
}

/*.stickycolumn, .scrollable-table table tbody tr td:first-child, .scrollable-table thead tr th:first-child, .sx-tableBody tr td:first-child {
    position: sticky !important;
    z-index: 1;
    background-color: #ffffff
}*/

